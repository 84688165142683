<template>
  <v-card>
    <v-container grid-list-xl>
      <v-layout wrap>
        <v-flex xs12>
          <span class="H5-Primary-Left">
            Automated EOD Reports
          </span>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-space-around>
        <v-flex xs12 sm10>
          <loading-wrapper :loading="loading">
            <v-sheet class="eod-sheet">
              <v-container class="pa-2">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      label="Search Emails"
                      prepend-inner-icon="mdi-magnify"
                      v-model="search"
                      hide-details
                    />
                  </v-flex>
                  <v-flex xs12 style="max-height: 300px; overflow: scroll;">
                    <template v-for="(sub, index) of filteredSubs">
                      <v-layout
                        justify-space-between
                        class="pa-1"
                        @click="editUser(sub)"
                        style="cursor: pointer;"
                        :key="sub.email"
                      >
                        <v-flex>
                          <span class="Body-1-Black-High-Emphasis-Left" style="font-size: 16px;">{{
                            sub.email
                          }}</span>
                        </v-flex>
                        <v-flex align-self-center class="reporting">
                          Receiving
                          {{ sub.units.length }}/{{ maxUnits }}
                          reports
                        </v-flex>
                        <v-flex shrink>
                          <v-icon @click.stop="removeUser(index)">
                            mdi-close
                          </v-icon>
                        </v-flex>
                      </v-layout>
                      <v-divider
                        light
                        :key="index"
                        v-if="index + 1 !== subscribers.length"
                      ></v-divider>
                    </template>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-sheet>
          </loading-wrapper>
        </v-flex>
        <v-flex xs12 sm10>
          <v-btn flat color="primary" @click="addUser">
            <v-icon>mdi-plus </v-icon>Add E-mail
          </v-btn>
        </v-flex>
      </v-layout>
      <email-modal
        :dialog.sync="modal.dialog"
        :isNew="modal.isNew"
        :subscriber="modal.subscriber"
        :site="site"
        @createSubscriber="createSubcriber"
      />
    </v-container>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import emailModal from './email_modal';

export default {
  props: ['site', 'modified'],
  components: {
    emailModal,
  },
  data: () => ({
    modal: {
      dialog: false,
      subscriber: {},
    },
    search: '',
    subscribers: [],
    backupSubscribers: [],
    loading: false,
    isModified: false,
  }),

  watch: {
    subscribers: {
      handler() {
        const isModified = !isEqual(this.subscribers, this.backupSubscribers);
        this.$emit('update:modified', isModified);
        if (isModified) {
          this.$emit('modified');
          this.isModified = true;
        }
      },
      deep: true,
    },
  },

  computed: {
    maxUnits() {
      if (this.site) {
        return this.site.locations.length;
      }
      return 0;
    },
    filteredSubs() {
      return this.subscribers.filter((e) => e.email.includes(this.search));
    },
  },

  methods: {
    createSubcriber() {
      this.subscribers.push(this.modal.subscriber);
    },
    async fetchSubscribers() {
      try {
        this.loading = true;
        const { data } = await this.api.get(`/report/eod/group/${this.site.id}/subscribers`);
        this.subscribers = data.subscribers;
        this.backupSubscribers = cloneDeep(data.subscribers);
      } catch (err) {
        console.error(err);
        this.$toast.error('could not fetch subscribers');
      }
      this.loading = false;
    },

    editUser(sub) {
      this.modal.dialog = true;
      this.modal.subscriber = sub;
      this.modal.isNew = false;
    },

    removeUser(index) {
      this.subscribers.splice(index, 1);
    },

    addUser() {
      this.modal.dialog = true;
      this.modal.subscriber = {
        email: '',
        units: this.site.locations.map((e) => ({ id: e.id })),
      };
      this.modal.isNew = true;
    },

    async save() {
      if (!this.isModified) return;
      const { data } = await this.api.put(`/report/eod/group/${this.site.id}/subscribers`, {
        subscribers: this.subscribers,
      });
      this.subscribers = data.subscribers;
      this.backupSubscribers = cloneDeep(data.subscribers);
    },

    cancel() {
      this.subscribers = this.backupSubscribers;
      this.backupSubscribers = cloneDeep(this.subscribers);
      this.isModified = false;
    },
  },
  mounted() {
    this.fetchSubscribers();
  },
};
</script>

<style scoped>
.eod-sheet {
  border: 1px solid #868686;
  border-radius: 5px;
}
.reporting {
  font-family: Avenir;
  font-size: 13.5px;
  font-weight: normal;
  text-decoration: underline;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
}
</style>
