<template>
  <v-container grid-list-xl style="margin-bottom: 40px;">
    <v-layout wrap style="margin-bottom: 40px;">
      <v-flex xs12>
        <view-title />
      </v-flex>
      <loading-wrapper :loading="loading">
        <v-flex xs12>
          <eod-reports :site="site" ref="subscribers" :modified.sync="modifiedSubs" />
        </v-flex>
        <v-flex xs12>
          <sales-distribution :site="site" ref="distribution" :modified.sync="modifiedSales" />
        </v-flex>
      </loading-wrapper>
      <v-flex xs12 v-if="modified">
        <save-footer :saveLabel="'Save'" :saveAction="save" :cancelAction="cancel" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import eodReports from './reports_subscriber';
import salesDistribution from './sales_distribution';

export default {
  components: {
    eodReports,
    salesDistribution,
  },
  props: ['site_id', 'multigroup_id'],
  data: () => ({
    loading: true,
    modifiedSubs: false,
    modifiedSales: false,
    site: {},
  }),
  computed: {
    modified() {
      return this.modifiedSales || this.modifiedSubs;
    },
  },
  methods: {
    ...mapActions('sites', ['getLocationGroup']),
    async fetchSite() {
      this.loading = true;
      try {
        this.site = await this.getLocationGroup({
          id: this.site_id,
          nocache: true,
        });
      } catch {
        this.site = {};
        this.$toast.error('Could not fetch site details');
      }
      this.loading = false;
    },
    async save() {
      try {
        await this.$refs.subscribers.save();
        await this.$refs.distribution.save();
        this.$toast('Report Configuration Saved');
      } catch (err) {
        this.$toast.error('Could not save report configuration');
        console.error(err);
      }
    },
    cancel() {
      this.$refs.subscribers.cancel();
      this.$refs.distribution.cancel();
    },
  },
  async mounted() {
    await this.fetchSite();
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Sales Report',
      to: { name: 'report-sites', params: { multigroup_id: this.multigroup_id } },
      last: false,
    });
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 1,
      title: this.site.name,
      to: {
        name: 'report-site-details',
        params: { multigroup_id: this.multigroup_id, site_id: this.site_id },
      },
      last: false,
    });
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 2,
      title: 'Report Configuration',
      to: {
        name: 'report-site-settings',
        params: { multigroup_id: this.multigroup_id, site_id: this.site_id },
      },
    });
  },
};
</script>

<style></style>
