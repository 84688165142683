import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { persistent: "", width: "800px", height: "600px" },
      model: {
        value: _vm.dialogSync,
        callback: function($$v) {
          _vm.dialogSync = $$v
        },
        expression: "dialogSync"
      }
    },
    [
      _c(
        VForm,
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            VCard,
            { staticClass: "px-2 py-2" },
            [
              _c(VCardTitle, { staticClass: "H4-Secondary-Center" }, [
                _vm._v(_vm._s(_vm.isNew ? "Add" : "Edit") + " Recipient")
              ]),
              _c(
                VLayout,
                { staticClass: "ml-3", attrs: { wrap: "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs5: "" } },
                    [
                      _c(VTextField, {
                        attrs: { label: "E-mail", rules: _vm.emailRules },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(VFlex, { attrs: { xs12: "" } }, [
                    _c("span", { staticClass: "H6-Secondary-Left" }, [
                      _vm._v("\n            Units/Cost Centres\n          ")
                    ]),
                    _c(
                      "div",
                      { staticClass: "Body-1-Black-High-Emphasis-Left" },
                      [
                        _vm._v(
                          "\n            Select which reports this user will receive\n          "
                        )
                      ]
                    )
                  ]),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          label: "Search Units",
                          "prepend-inner-icon": "mdi-magnify",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    [
                      _c(
                        VList,
                        _vm._l(_vm.filteredUnits, function(unit) {
                          return _c(
                            VListTile,
                            { key: unit.id },
                            [
                              _c(
                                VListTileAction,
                                [
                                  _c(VCheckbox, {
                                    attrs: { value: unit.id },
                                    model: {
                                      value: _vm.unitsSelected,
                                      callback: function($$v) {
                                        _vm.unitsSelected = $$v
                                      },
                                      expression: "unitsSelected"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                VListTileContent,
                                [
                                  unit.meta && unit.meta.unit
                                    ? _c(VListTileTitle, [
                                        _vm._v("Unit " + _vm._s(unit.meta.unit))
                                      ])
                                    : _c(VListTileTitle, [
                                        _vm._v(_vm._s(unit.name))
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { flat: "", color: "blue" },
                      on: { click: _vm.close }
                    },
                    [_vm._v("CANCEL")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { flat: "", color: "blue", disabled: !_vm.valid },
                      on: { click: _vm.save }
                    },
                    [_vm._v("save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }