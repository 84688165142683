import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { staticStyle: { "margin-bottom": "40px" }, attrs: { "grid-list-xl": "" } },
    [
      _c(
        VLayout,
        { staticStyle: { "margin-bottom": "40px" }, attrs: { wrap: "" } },
        [
          _c(VFlex, { attrs: { xs12: "" } }, [_c("view-title")], 1),
          _c(
            "loading-wrapper",
            { attrs: { loading: _vm.loading } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("eod-reports", {
                    ref: "subscribers",
                    attrs: { site: _vm.site, modified: _vm.modifiedSubs },
                    on: {
                      "update:modified": function($event) {
                        _vm.modifiedSubs = $event
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("sales-distribution", {
                    ref: "distribution",
                    attrs: { site: _vm.site, modified: _vm.modifiedSales },
                    on: {
                      "update:modified": function($event) {
                        _vm.modifiedSales = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.modified
            ? _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c("save-footer", {
                    attrs: {
                      saveLabel: "Save",
                      saveAction: _vm.save,
                      cancelAction: _vm.cancel
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }