import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "pb-4" },
    [
      _c(
        VContainer,
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            VLayout,
            { attrs: { wrap: "" } },
            [
              _c(VFlex, { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "H5-Primary-Left" }, [
                  _vm._v("\n          Sales Distribution\n        ")
                ])
              ])
            ],
            1
          ),
          _c(
            "loading-wrapper",
            { attrs: { loading: _vm.loading } },
            _vm._l(_vm.periods, function(d) {
              return _c(
                VLayout,
                { key: d.label, attrs: { "align-content-center": "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { "align-self-center": "", xs2: "" } },
                    [
                      _c(
                        "span",
                        { staticClass: "Subtitle-2-Black-High-Emphasis-Left" },
                        [
                          _vm._v(
                            "\n            " + _vm._s(d.label) + "\n          "
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs3: "" } },
                    [
                      _c(VTextField, {
                        staticClass: "border-label-input",
                        attrs: {
                          type: "time",
                          outline: "",
                          label: "Start Time",
                          "hide-details": "",
                          "append-icon": "mdi-clock-outline",
                          disabled: true
                        },
                        model: {
                          value: d.start,
                          callback: function($$v) {
                            _vm.$set(d, "start", $$v)
                          },
                          expression: "d.start"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { "align-self-center": "", shrink: "" } },
                    [_c(VIcon, [_vm._v("mdi-arrow-right")])],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs3: "" } },
                    [
                      _c(VTextField, {
                        staticClass: "border-label-input",
                        attrs: {
                          "append-icon": "mdi-clock-outline",
                          type: "time",
                          outline: "",
                          label: "End Time",
                          "hide-details": "",
                          disabled: true
                        },
                        model: {
                          value: d.end,
                          callback: function($$v) {
                            _vm.$set(d, "end", $$v)
                          },
                          expression: "d.end"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }