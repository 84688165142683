import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(
        VContainer,
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            VLayout,
            { attrs: { wrap: "" } },
            [
              _c(VFlex, { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "H5-Primary-Left" }, [
                  _vm._v("\n          Automated EOD Reports\n        ")
                ])
              ])
            ],
            1
          ),
          _c(
            VLayout,
            { attrs: { wrap: "", "justify-space-around": "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "", sm10: "" } },
                [
                  _c(
                    "loading-wrapper",
                    { attrs: { loading: _vm.loading } },
                    [
                      _c(
                        VSheet,
                        { staticClass: "eod-sheet" },
                        [
                          _c(
                            VContainer,
                            { staticClass: "pa-2" },
                            [
                              _c(
                                VLayout,
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    VFlex,
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          label: "Search Emails",
                                          "prepend-inner-icon": "mdi-magnify",
                                          "hide-details": ""
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VFlex,
                                    {
                                      staticStyle: {
                                        "max-height": "300px",
                                        overflow: "scroll"
                                      },
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _vm._l(_vm.filteredSubs, function(
                                        sub,
                                        index
                                      ) {
                                        return [
                                          _c(
                                            VLayout,
                                            {
                                              key: sub.email,
                                              staticClass: "pa-1",
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                "justify-space-between": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editUser(sub)
                                                }
                                              }
                                            },
                                            [
                                              _c(VFlex, [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "Body-1-Black-High-Emphasis-Left",
                                                    staticStyle: {
                                                      "font-size": "16px"
                                                    }
                                                  },
                                                  [_vm._v(_vm._s(sub.email))]
                                                )
                                              ]),
                                              _c(
                                                VFlex,
                                                {
                                                  staticClass: "reporting",
                                                  attrs: {
                                                    "align-self-center": ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Receiving\n                        " +
                                                      _vm._s(sub.units.length) +
                                                      "/" +
                                                      _vm._s(_vm.maxUnits) +
                                                      "\n                        reports\n                      "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                VFlex,
                                                { attrs: { shrink: "" } },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.removeUser(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          mdi-close\n                        "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          index + 1 !== _vm.subscribers.length
                                            ? _c(VDivider, {
                                                key: index,
                                                attrs: { light: "" }
                                              })
                                            : _vm._e()
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { attrs: { xs12: "", sm10: "" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { flat: "", color: "primary" },
                      on: { click: _vm.addUser }
                    },
                    [
                      _c(VIcon, [_vm._v("mdi-plus ")]),
                      _vm._v("Add E-mail\n        ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("email-modal", {
            attrs: {
              dialog: _vm.modal.dialog,
              isNew: _vm.modal.isNew,
              subscriber: _vm.modal.subscriber,
              site: _vm.site
            },
            on: {
              "update:dialog": function($event) {
                return _vm.$set(_vm.modal, "dialog", $event)
              },
              createSubscriber: _vm.createSubcriber
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }