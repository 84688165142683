<template>
  <v-card class="pb-4">
    <v-container grid-list-xl>
      <v-layout wrap>
        <v-flex xs12>
          <span class="H5-Primary-Left">
            Sales Distribution
          </span>
        </v-flex>
      </v-layout>
      <loading-wrapper :loading="loading">
        <v-layout align-content-center v-for="d in periods" :key="d.label">
          <v-flex align-self-center xs2>
            <span class="Subtitle-2-Black-High-Emphasis-Left">
              {{ d.label }}
            </span>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              type="time"
              outline
              label="Start Time"
              hide-details
              class="border-label-input"
              append-icon="mdi-clock-outline"
              v-model="d.start"
              :disabled="true"
            />
          </v-flex>
          <v-flex align-self-center shrink>
            <v-icon>mdi-arrow-right</v-icon>
          </v-flex>
          <v-flex xs3>
            <v-text-field
              append-icon="mdi-clock-outline"
              type="time"
              outline
              label="End Time"
              hide-details
              class="border-label-input"
              v-model="d.end"
              :disabled="true"
            />
          </v-flex>
        </v-layout>
      </loading-wrapper>
    </v-container>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export default {
  props: ['site', 'modified'],
  components: {},
  data: () => ({
    periods: [],
    backupPeriods: [],
    config: {},
    loading: false,
  }),
  watch: {
    periods: {
      handler() {
        const isModified = !isEqual(this.periods, this.backupPeriods);
        this.$emit('update:modified', isModified);
      },
      deep: true,
    },
  },
  methods: {
    async fetchPeriods() {
      try {
        this.loading = true;
        const { data } = await this.api.get(`/config/${this.site.id}`);
        this.config = data;
        this.periods = data.meal_periods || [
          {
            label: 'Breakfast',
            start: '00:00',
            end: '10:59',
          },
          {
            label: 'Lunch',
            start: '11:00',
            end: '14:59',
          },
          {
            label: 'Dinner',
            start: '15:00',
            end: '23:59',
          },
        ];
        this.backupPeriods = cloneDeep(this.periods);
      } catch (err) {
        this.$toast.error('could not fetch meal periods');
        console.error(err);
      }

      this.loading = false;
    },
    async save() {
      try {
        const { data } = await this.api.put(`/config/${this.site.id}`, {
          ...this.config,
          meal_periods: this.periods,
        });
        this.periods = data.meal_periods;
        this.backupPeriods = cloneDeep(data.meal_periods);
      } catch (err) {
        this.$toast.error('could not save sales distribution');
      }
    },
    cancel() {
      this.periods = this.backupPeriods;
      this.backupPeriods = cloneDeep(this.periods);
    },
  },
  mounted() {
    this.fetchPeriods();
  },
};
</script>

<style></style>
